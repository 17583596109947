<template>
  <v-row v-if="masterRequest" justify="center">
    <BaseDialog
      :dialog="dialog"
      width="35%"
      :title="loading && listMaster.length === 0 ? $t('content.please_wait') : $t('content.master_account_request')"
      :subTitle="listMaster.length > 0 ? $t('content.select_master_account') : ''"
      @close="close()"
    >
      <v-progress-linear indeterminate v-if="loading && listMaster.length === 0" color="primary"></v-progress-linear>
      <template v-if="listMaster.length > 0">
        <v-list nav>
          <v-list-item v-for="item in listMaster" :key="item.customer_user_profile_id">
            <v-list-item-avatar>
              <v-icon class="grey lighten-1" dark>
                mdi-account
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.user_display"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                block
                class="mr-1 indigo white--text choice-button no-transform"
                :loading="loadingMasterRequest"
                @click="sendRequest(item)"
              >
                {{ $t('content.send_request') }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
      <template v-else-if="!loading && listMaster.length === 0">
        <v-row>
          <v-col cols="12" class="text-center">
            <h3>{{ $t('content.sure_upgrade') }}</h3>
          </v-col>
        </v-row>
        <v-row class="btn-container">
          <v-col cols="6">
            <v-btn
              block
              class="mr-1 indigo white--text choice-button no-transform"
              :loading="loadingMasterRequest"
              @click="sendRequest()"
            >
              {{ $t('content.send_request') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="danger" class="white--text no-transform" @click="close()">
              {{ $t('content.cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </BaseDialog>
    <BaseAlert ref="alert" />
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseDialog from '@/components/base/BaseDialog';
import BaseAlert from '@/components/base/BaseAlert';

export default {
  name: 'ListMasterRequest',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    masterRequest: {
      type: Object,
      default: null,
    },
  },
  components: {
    BaseDialog,
    BaseAlert,
  },
  data() {
    return {
      loading: false,
      loadingMasterRequest: false,
    };
  },
  watch: {
    dialog(val) {
      if (val === true) {
        this.fetchMasterRequest();
      }
    },
  },
  computed: {
    ...mapGetters({
      listMaster: 'Account/listMaster',
    }),
  },
  methods: {
    close() {
      this.$emit('close');
    },
    fetchMasterRequest() {
      this.loading = true;
      this.$store
        .dispatch('Account/requestMaster', this.masterRequest.id)
        .catch(err => this.$toast.error(err.message))
        .finally(() => (this.loading = false));
    },
    sendRequest(item) {
      this.loadingMasterRequest = true;
      const payload = item
        ? {
            master_requested_from_id: this.masterRequest.id,
            customer_user_profile_id: item.customer_user_profile_id,
          }
        : { master_requested_from_id: this.masterRequest.id };
      this.$store
        .dispatch('Account/sendRequestMaster', payload)
        .then(() => {
          this.$refs.alert
            .open({
              type: 'confirm',
              title: `${this.$t('form.success')}`,
              message: `${this.$t('form.request_master_success')}`,
              icon: 'success',
              btnAgreeText: 'OK',
            })
            .then(() => {
              this.close();
            });
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          this.loadingMasterRequest = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  margin-top: 10px;
}
.no-transform {
  text-transform: none;
}
</style>
