var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-column flex-md-row mb-4"},[_c('v-btn',{staticClass:"no-transform mr-md-3 mb-3 mb-md-0",attrs:{"dark":"","color":"indigo","depressed":""},on:{"click":function($event){return _vm.$router.push({ name: 'AccountChangePassword' })}}},[_vm._v(" "+_vm._s(_vm.$t('signUpContent.change_password_title'))+" ")]),_c('v-btn',{staticClass:"no-transform mr-md-3 mb-3 mb-md-0",attrs:{"dark":"","color":"indigo","depressed":""},on:{"click":function($event){return _vm.$router.push({ name: 'AccountTimeAndLang' })}}},[_vm._v(" "+_vm._s(_vm.$t('account.change_account_time_and_lang'))+" ")]),_c('v-btn',{staticClass:"no-transform mr-md-3 mb-3 mb-md-0",attrs:{"dark":"","color":"indigo","depressed":""},on:{"click":function($event){return _vm.$router.push({ name: 'PaymentMethods' })}}},[_vm._v(" "+_vm._s(_vm.$t('account.edit_payment_methods'))+" ")]),(!_vm.hasVerified)?_c('v-btn',{staticClass:"no-transform mr-md-3 mb-3 mb-md-0",attrs:{"dark":"","color":"indigo","depressed":""},on:{"click":_vm.showVerifyForm}},[_vm._v(" "+_vm._s(_vm.$t('content.verify_my_company_title'))+" ")]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-center mt-md-8"},[_c('div',{staticClass:"text-h5 d-none d-sm-flex title pr-2"},[_vm._v(" "+_vm._s(_vm.userAccountProfile.company_name)+" ")]),(_vm.hasVerified)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-checkbox-marked-circle")]),_vm._v(" "+_vm._s(_vm.$t('content.verified'))+" ")],1)]}}],null,false,2354231036)},[_c('span',[_vm._v(_vm._s(_vm.$t('content.company_relationship_verified')))])])],1):_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert-circle")]),_vm._v(" "+_vm._s(_vm.$t('content.unverified'))+" ")],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('content.company_relationship_unverified')))])])],1)])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticStyle:{"border-style":"dashed"}})],1)],1),_c('v-data-table',{staticClass:"custom-table",attrs:{"hide-default-footer":"","disable-pagination":"","headers":_vm.headers,"items":_vm.corporate,"loading":_vm.loading},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('content.nodata'))+" ")]},proxy:true},{key:"item.is_master",fn:function(ref){
var item = ref.item;
return [(!item.is_master)?_c('div',[_c('v-btn',{staticClass:"rounded py-2 px-3 regular-plate",attrs:{"text":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('account.regular_account'))+" ")])],1):_c('div',[_c('v-btn',{staticClass:"rounded pa-2 master-plate",attrs:{"text":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('account.master_account'))+" ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"no-transform",attrs:{"outlined":"","color":"indigo","disabled":item.is_master},on:{"click":function($event){return _vm.requestMaster(item)}}},[_vm._v(" "+_vm._s(_vm.$t('account.request_master_account'))+" ")])]}}],null,true),model:{value:(_vm.selectedCorporate),callback:function ($$v) {_vm.selectedCorporate=$$v},expression:"selectedCorporate"}}),_c('BaseAlert',{ref:"alert"}),_c('ListMasterRequest',{attrs:{"masterRequest":_vm.selectedItem,"dialog":_vm.dialogListMasterRequest},on:{"close":_vm.closeDialog}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.verifyDialog),callback:function ($$v) {_vm.verifyDialog=$$v},expression:"verifyDialog"}},[_c('v-card',[_c('div',{staticClass:"pa-8"},[_c('div',{staticClass:"text-h5 font-weight-bold pt-3 dialog-title"},[_vm._v(" "+_vm._s(_vm.$t('content.verify_my_company_title'))+" ")]),_c('div',{staticClass:"pb-8 pt-2 dialog-body"},[_vm._v(" "+_vm._s(_vm.$t('content.verify_my_company_desc'))+" ")]),_c('BaseFormGroup',{attrs:{"name":_vm.$t('form.office'),"validator":_vm.$v.verify.officeId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('BaseLabel',{attrs:{"title":_vm.$t('form.office')}}),_c('v-autocomplete',_vm._b({staticClass:"rounded-lg",attrs:{"placeholder":_vm.$t('form.office'),"items":_vm.offices,"outlined":"","dense":"","item-value":"id","item-text":"name"},on:{"input":function($event){return _vm.$v.verify.officeId.$touch()},"blur":function($event){return _vm.$v.verify.officeId.$touch()}},model:{value:(_vm.verify.officeId),callback:function ($$v) {_vm.$set(_vm.verify, "officeId", $$v)},expression:"verify.officeId"}},'v-autocomplete',attrs,false))]}}])}),_c('BaseFormGroup',{attrs:{"name":_vm.$t('form.service'),"validator":_vm.$v.verify.contactId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('BaseLabel',{attrs:{"title":_vm.$t('form.service')}}),_c('v-autocomplete',_vm._b({staticClass:"rounded-lg",attrs:{"placeholder":_vm.$t('form.service'),"items":_vm.services,"outlined":"","dense":"","item-value":"id","item-text":"business_segment_name"},on:{"input":function($event){return _vm.$v.verify.contactId.$touch()},"blur":function($event){return _vm.$v.verify.contactId.$touch()}},model:{value:(_vm.verify.contactId),callback:function ($$v) {_vm.$set(_vm.verify, "contactId", $$v)},expression:"verify.contactId"}},'v-autocomplete',attrs,false))]}}])}),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"dialog-button font-weight-bold mr-3",attrs:{"color":"primary","outlined":""},on:{"click":_vm.onCancelVerify}},[_vm._v(" "+_vm._s(_vm.$t('content.cancel'))+" ")]),_c('v-btn',{staticClass:" dialog-button font-weight-bold",attrs:{"color":"primary","loading":_vm.verifyButtonLoading},on:{"click":_vm.onVerify}},[_vm._v(" "+_vm._s(_vm.$t('content.submit'))+" ")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }