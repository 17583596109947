<template>
  <div>
    <div class="d-flex flex-column flex-md-row mb-4">
      <v-btn
        class="no-transform mr-md-3 mb-3 mb-md-0"
        dark
        color="indigo"
        depressed
        @click="$router.push({ name: 'AccountChangePassword' })"
      >
        {{ $t('signUpContent.change_password_title') }}
      </v-btn>
      <v-btn class="no-transform mr-md-3 mb-3 mb-md-0" dark color="indigo" depressed @click="$router.push({ name: 'AccountTimeAndLang' })">
        {{ $t('account.change_account_time_and_lang') }}
      </v-btn>
      <v-btn class="no-transform mr-md-3 mb-3 mb-md-0" dark color="indigo" depressed @click="$router.push({ name: 'PaymentMethods' })">
        {{ $t('account.edit_payment_methods') }}
      </v-btn>
      <v-btn v-if="!hasVerified" class="no-transform mr-md-3 mb-3 mb-md-0" dark color="indigo" depressed @click="showVerifyForm">
        {{ $t('content.verify_my_company_title') }}
      </v-btn>
    </div>

    <v-row>
      <v-col cols="12" md="6">
        <div class="d-flex align-center mt-md-8">
          <div class="text-h5 d-none d-sm-flex title pr-2">
            {{ userAccountProfile.company_name }}
          </div>
          <span v-if="hasVerified">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip class="ma-2" color="green" text-color="white" v-bind="attrs" v-on="on">
                  <v-icon left>mdi-checkbox-marked-circle</v-icon>
                  {{ $t('content.verified') }}
                </v-chip>
              </template>
              <span>{{ $t('content.company_relationship_verified') }}</span>
            </v-tooltip>
          </span>
          <span v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip class="ma-2" color="red" text-color="white" v-bind="attrs" v-on="on">
                  <v-icon left>mdi-alert-circle</v-icon>
                  {{ $t('content.unverified') }}
                </v-chip>
              </template>
              <span>{{ $t('content.company_relationship_unverified') }}</span>
            </v-tooltip>
          </span>
        </div>
      </v-col>
      <v-col cols="12">
        <v-divider style="border-style: dashed" />
      </v-col>
    </v-row>

    <v-data-table
      v-model="selectedCorporate"
      hide-default-footer
      disable-pagination
      :headers="headers"
      :items="corporate"
      :loading="loading"
      class="custom-table"
    >
      <template #no-data>
        {{ $t('content.nodata') }}
      </template>
      <template #[`item.is_master`]="{ item }">
        <div v-if="!item.is_master">
          <v-btn text small class="rounded py-2 px-3 regular-plate">
            {{ $t('account.regular_account') }}
          </v-btn>
        </div>
        <div v-else>
          <v-btn text small class="rounded pa-2 master-plate">
            {{ $t('account.master_account') }}
          </v-btn>
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn class="no-transform" outlined color="indigo" @click="requestMaster(item)" :disabled="item.is_master">
          {{ $t('account.request_master_account') }}
        </v-btn>
      </template>
    </v-data-table>
    <BaseAlert ref="alert" />
    <ListMasterRequest :masterRequest="selectedItem" :dialog="dialogListMasterRequest" @close="closeDialog" />

    <v-dialog persistent v-model="verifyDialog" max-width="500">
      <v-card>
        <div class="pa-8">
          <div class="text-h5 font-weight-bold pt-3 dialog-title">
            {{ $t('content.verify_my_company_title') }}
          </div>
          <div class="pb-8 pt-2 dialog-body">
            {{ $t('content.verify_my_company_desc') }}
          </div>
          <BaseFormGroup :name="$t('form.office')" :validator="$v.verify.officeId">
            <template slot-scope="{ attrs }">
              <BaseLabel :title="$t('form.office')" />
              <v-autocomplete
                v-model="verify.officeId"
                v-bind="attrs"
                :placeholder="$t('form.office')"
                :items="offices"
                outlined
                dense
                class="rounded-lg"
                item-value="id"
                item-text="name"
                @input="$v.verify.officeId.$touch()"
                @blur="$v.verify.officeId.$touch()"
              />
            </template>
          </BaseFormGroup>
          <BaseFormGroup :name="$t('form.service')" :validator="$v.verify.contactId">
            <template slot-scope="{ attrs }">
              <BaseLabel :title="$t('form.service')" />
              <v-autocomplete
                v-model="verify.contactId"
                v-bind="attrs"
                :placeholder="$t('form.service')"
                :items="services"
                outlined
                dense
                class="rounded-lg"
                item-value="id"
                item-text="business_segment_name"
                @input="$v.verify.contactId.$touch()"
                @blur="$v.verify.contactId.$touch()"
              />
            </template>
          </BaseFormGroup>
          <div class="text-center">
            <v-btn color="primary" class="dialog-button font-weight-bold mr-3" outlined @click="onCancelVerify">
              {{ $t('content.cancel') }}
            </v-btn>

            <v-btn color="primary" class=" dialog-button font-weight-bold" @click="onVerify" :loading="verifyButtonLoading">
              {{ $t('content.submit') }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
// import TableFooter from "@/components/table/TableFooterCompany";
import ListMasterRequest from '@/components/master-request/ListMasterRequest';
import BaseAlert from '@/components/base/BaseAlert';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'AccountSetting',
  validations: {
    verify: {
      officeId: { required },
      contactId: { required },
    },
  },
  components: { BaseAlert, ListMasterRequest },

  data() {
    return {
      corporate: [],
      totalCorporate: 0,
      selectedCorporate: [],
      userAccountProfile: null,
      verifyDialog: false,
      hasVerified: false,
      verifyButtonLoading: false,
      verify: {
        officeId: null,
        contactId: null,
      },
      loading: false,
      dialogListMasterRequest: false,
      selectedItem: null,
      headers: [
        { text: this.$t('content.corporate_name'), value: 'company' },
        {
          text: this.$t('content.status'),
          value: 'is_master',
          sortable: false,
        },
        {
          text: this.$t('content.actions'),
          value: 'actions',
          width: 300,
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      offices: 'Account/offices',
      services: 'Account/services',
      user: 'Auth/user',
    }),
  },
  watch: {
    'verify.officeId'(val) {
      this.fetchServices(val);
    },
  },
  methods: {
    closeDialog() {
      this.dialogListMasterRequest = false;
      this.selectedItem = null;
    },
    fetchCompany() {
      const profileId = this.user?.customer_user_profile_id;
      this.$store
        .dispatch('Account/getCompany', profileId)
        .then(data => {
          this.corporate = data;
          this.totalCorporate = data.length;
        })
        .catch(err => this.$toast.error(err.message));
    },
    requestMaster(item) {
      this.selectedItem = item;
      this.dialogListMasterRequest = true;
    },
    fetchAccountProfile() {
      const profileId = this.user?.customer_user_profile_id;
      this.$store
        .dispatch('Account/getProfile', profileId)
        .then(data => {
          this.userAccountProfile = data;
          this.hasVerified = data.is_portal_info_verified;
        })
        .catch(err => this.$toast.error(err.message));
    },
    fetchOffices() {
      this.$store.dispatch('Account/fetchOffices');
    },
    fetchServices(id) {
      this.$store.dispatch('Account/fetchServices', id).then(() => {
        this.verify.contactId = null;
      });
    },
    showVerifyForm() {
      this.verifyDialog = true;
    },
    onCancelVerify() {
      this.popperHide = false;
      this.infoDialog = false;
      this.verifyDialog = false;
    },
    onVerify() {
      this.$v.verify.$touch();
      if (this.$v.verify.$invalid) return false;

      this.verifyButtonLoading = true;
      this.$store
        .dispatch('Account/verifyMyCompany', this.verify.contactId)
        .then(() => {
          this.onSuccessVerified();
        })
        .catch(err => {
          this.$toast.error(err.message);
        })
        .finally(() => {
          this.verifyButtonLoading = false;
        });
    },
    onSuccessVerified() {
      this.popperHide = false;
      this.infoDialog = true;
      this.verifyDialog = false;
    },
  },
  created() {
    this.fetchCompany();
    this.fetchAccountProfile();
    this.fetchOffices();
  },
};
</script>

<style lang="scss" scoped>
.choice-button {
  margin-left: 10px;
  margin-top: 10px;
  text-transform: none;
}
.regular-plate {
  background-color: #dfddce;
  color: #6f6b49;
  text-align: center;
}
.master-plate {
  background-color: #e49f15;
  color: #ffffff;
  text-align: center;
}
.no-transform {
  text-transform: none;
}
</style>
